import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'output' ]
  static values = {
    debounce: { type: Number, default: 500 }
  }

  connect() {
    setTimeout(this.fetch.bind(this), this.debounceValue);
  }

  fetch() {
    fetch(this.element.dataset.url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(response => response.text())
    .then(html => {
      if (this.hasOutputTarget ) {
        this.outputTarget.innerHTML = html
      } else {
        this.element.outerHTML = html
      }
    })
  }
}
